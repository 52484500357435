<script setup lang="ts">
import { defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { auth } from '@/plugins/auth';
import { useStoredLocale } from '@/composables/useStoredLocale';

import http from '@/plugins/http';

useStoredLocale();

defineComponent({
  name: 'TheApp',
});

const router = useRouter();

onMounted(() => {
  http.addHooks({
    beforeError: [
      (error) => {
        if (error.response.status === 401) {
          auth.removeUser();
          router.push({
            name: 'Home',
          });
        }
        return error;
      },
    ],
  });
});
</script>

<template>
  <RouterView name="layout" />
</template>
